const PUBLIC_URL = process.env.REACT_APP_PUBLIC_URL

export default function Main(userRefId, userName) {
  return {
    title: 'Brahman Community Network (BCN)',
    text: `*Join the Brahman Community Network (BCN) Portal Today!*

BCN is a dedicated marketplace for the Brahman community, where you can buy and sell products and services exclusively within the Brahman community.

You can register as:
- Individual, Professional, or Business
- Create your personal profile
- Register your business, if applicable
- Find Matrimonial Matches (Coming Soon)
- Post or search for Jobs

Subscription:
For just ₹599 per year, enjoy full access to all BCN features and expand your network!

Let's unite and strengthen our Brahman community!

Ref Link:
${PUBLIC_URL + '/signUp?refId=' + encodeURIComponent(userRefId) + '&referredby=' + encodeURIComponent(userName)}

Please share this message across all Brahman groups and help grow our community.

Best regards,
Team BCN
M - 9910681030
Helpline - 8047761776`
  }
}
